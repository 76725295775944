<template> 
  <div>
  <app-menu/>
  <v-container class="grey lighten-5" style="min-height:756px;"  >
    <div class="text-center">
      <v-dialog v-model="isLoad" persistent width="300">
         <v-card color="primary" dark >
          <v-card-text>
            cargando
            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card> 
      </v-dialog>
    </div> 

    <div class="text-center">
    <v-dialog  v-model="dialogTransferencia"  width="500"  > 
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Transferencia electrónica
        </v-card-title> 
        <v-card-text style="color:black;">
          <br>
          Por favor, transfiere el importe de la orden a nuestra cuenta bancaria. 
          Recibirás nuestra confirmación de pedido por correo electrónico con los datos bancarios y el número de pedido. 
          Los bienes se reservarán 3 días y procesaremos el pedido inmediatamente tras las recepción del pago.
          <br>Por favor, incluye el número de orden de tu pedido en los detalles de la transferencia bancaria.
          <br>
          <!-- <p>
            <strong>Tu fecha de entrega ingresada se omitirá y se te solicitará de nuevo una vez que tu pago sea confirmado.</strong>
          </p> -->
        </v-card-text>
        <v-divider></v-divider> 
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogTransferencia = false" >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <v-dialog transition="dialog-top-transition" persistent  v-model="dialogRegalo"  max-width="700"  > 
      <v-card> 
          <v-card dark>
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title class="text-h5">Felicidades</v-card-title> 
                <v-card-subtitle class="text-h6">Has recibido un regalo por tu compra</v-card-subtitle> 
              </div> 
              <v-avatar class="ma-3"  size="125" tile >
                <v-img src="../../public/caja-de-regalo.svg"></v-img>
              </v-avatar>
            </div>
          </v-card> 
        <v-divider></v-divider>  
        <center class="my-5">Selecciona tu producto de regalo</center> 
        <!-- {{saleOrder.productoregalo}} -->
        <v-radio-group v-model="saleOrder.productoregalo">  
          <v-card v-for="producto in productosRegalo" :key="producto.value">
              <div width="98%" style="margin-left:15px" class="d-flex flex-no-wrap justify-space-between">
                <div class="my-9">
                  <v-radio :label="producto.name.substring(0,83)" :value="producto.m_product_id" ></v-radio>
                  <p style="color:#7D7D7D">{{producto.value}}</p> 
                </div> 
                <v-avatar class="ma-3"  size="125" tile >
                  <v-img :src="`${ubicacionImagenProducto}${producto.value}.jpg`" ></v-img>
                </v-avatar>
              </div>
          </v-card>
        </v-radio-group>  
        <v-card-actions>
          <v-btn color="primary" text @click="regaloSeleccionado()" >
            Aceptar
          </v-btn> 
          <v-spacer></v-spacer> 
          <v-alert v-if="msgError!=''" dense outlined type="error">
          {{msgError}}
          </v-alert>  
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div> 
    <template v-if="shopingcarlength > 0" >

      <v-alert v-if="msgSucces!=''" dense text type="success">
        {{msgSucces}}
      </v-alert>
      <v-alert v-if="msgError!='' && dialogRegalo == false" dense outlined type="error">
        {{msgError}}
      </v-alert> 
      <div v-if="creditoExedido == false">

      <!-- Muestra las direcciones -->
      <v-container v-if="isAddress && calculaTotalCar_fn() >= montoMinimoCompra " >
        <v-card>
          <v-card class="d-flex justify-center" v-model="direccion"  
            color="blue lighten-1" style="border-radius: 0px 0px 180px 0px; color:white;">  
            <div class="d-flex  justify-center">
                <div>
                  <br>
                  <v-card-title style="color:white;" class="text-h4">Selecciona la dirección a donde enviaremos tú pedido</v-card-title> 
                </div> 
                <v-avatar class="ma-1"  size="100" tile >
                  <v-img src="../../public/package.png"></v-img>
                </v-avatar>
              </div>
          </v-card>
          <div class="text-h5" justify="center" v-if=" userList ==0" dense outlined style="color:gray; text-align:center">
              No hay direcciones registradas
          </div> 
          <v-radio-group v-model="direccion"  v-if="userList !=0">
            <v-container >
              <v-row justify="center" >
                  <div class="  justify-center ">
                      <v-avatar class="ma-12"   tile  size="90%">
                        <v-img   src="../../public/pedido.gif"></v-img>
                      </v-avatar>
                  </div>
                <v-row md ="8" class="ml-auto">
                  <v-card v-for="userList in userList" :key="userList.c_location_id"
                    elevation="8"
                    class="ma-5"
                    max-width="314"
                    max-height="580">
                    <v-img
                      style=""
                      elevation=""
                      src="https://cdn.vuetifyjs.com/images/parallax/material2.jpg"
                      height="35px"
                    ></v-img>
                    <v-radio  :value="userList.c_location_id"></v-radio>
                    <v-card-title style="align-text:center;">{{userList.locationname}}</v-card-title>
                    <v-card-text>
                      
                      <h2 class="font-weight-light mt-1">País</h2>
                      <h4 style="font-family:Lucida, sans-serif;">
                      {{ userList.countryname }}
                      </h4>
                      <v-divider
                        color="to left,cyan,red"
                        class="mt-1"
                      ></v-divider>
                      <h2 class="font-weight-light mt-1">Estado</h2>
                      <h4 style="font-family:Lucida, sans-serif;">
                        {{ userList.description }}
                      </h4>
                      <v-divider
                        color="to left,cyan,red"
                        class="mt-1"
                      ></v-divider>
                      <h2 class="font-weight-light mt-1">Ciudad</h2>
                      <h4 style="font-family:Lucida, sans-serif;">
                        {{ userList.city }}
                      </h4>
                      <v-divider
                        color="to left,cyan,red"
                        class="mt-1"
                      ></v-divider>
                      <h2 class="font-weight-light mt-1">Colonia</h2>
                      <h4 style="font-family:Lucida, sans-serif;">
                        {{ userList.address2 }}
                      </h4>
                      <v-divider
                        color="to left,cyan,red"
                        class="mt-1"
                      ></v-divider>
                      <h2 class="font-weight-light mt-1">Delegación/Municipio</h2>
                      <h4 style="font-family:Lucida, sans-serif;">
                        {{ userList.address3 }}
                      </h4>
                      <v-divider
                        color="to left,cyan,red"
                        class="mt-1"
                      ></v-divider>
                      <h2 class="font-weight-light mt-1">Calle</h2>
                      <h4 style="font-family:Lucida, sans-serif;">
                        {{ userList.address1 }}
                      </h4>
                      <v-divider
                        color="to left,cyan,red"
                        class="mt-1"
                      ></v-divider>
                      <h2  class="font-weight-light mt-1">Teléfono</h2>
                      <h4 style="font-family:Lucida, sans-serif;">
                        {{ userList.office_number }}
                      </h4>
                      <v-divider
                        color="to left,cyan,red"
                        class="mt-1"
                      ></v-divider>
                      
                      <h2 class="font-weight-light mt-1">Código Postal</h2>
                      <h4 style="font-family:Lucida, sans-serif;">
                        {{ userList.postal }}
                      </h4>
                      <v-divider
                        color="to left,cyan,red"
                        class="mt-1"
                      ></v-divider>
                    </v-card-text>
                  </v-card>
                </v-row>
              </v-row>
            </v-container> 
          <br>
          </v-radio-group>
          <v-row justify="center" class="mx-auto">
            <v-btn justify="center" 
              dark color="blue"
              @click="sendAddress()">
              Elegir dirección
            </v-btn>
          </v-row>
          <br>
        </v-card>
        
      </v-container>
      
      <v-container v-if="isPago && calculaTotalCar_fn() >= montoMinimoCompra " style="min-height:656px;">
            <v-card style="min-height:186px;" class="justify-center"> 
              <v-card dark  color="blue lighten-1" style="border-radius:0px 0px 180px 0px;">
                <v-card-title class="text-h4">Elige tu método de pago</v-card-title>
              </v-card>
              <br>
              <v-btn class="text-center" color="blue" text
                @click="returnDireccion()">
                <v-icon>mdi-arrow-left</v-icon>Elegir otra dirección
              </v-btn>
              <!-- <p class="text-center" style="font-size: 1em;color :#909090">  
              Elige tu método de pago   
              </p>   -->
              <p class="text-center" style="font-size: 2em;color :#909090">  
              Total a pagar:
                <br><center>{{formatMXN(calculaTotalCar_fn())}}</center>  
              </p>   
              <v-container >
                <v-row dense> 
                    <v-col cols="12">
                    <v-card color="#">
                      <div class="d-flex flex-no-wrap justify-space-between">
                        <div>
                          <v-card-title class="text-h5">Se enviará a la dirección de nombre: {{direccionEnvio.locationname}}</v-card-title>
                          <v-card-title class="text-h5">Genera Factura</v-card-title> 
                          <v-card-subtitle >
                            <v-row no-gutters>
                              <v-col cols="6" md="4">
                                <v-switch disabled v-model="factura" :label="factura?'Si':'No'"></v-switch>
                              </v-col>
                              <v-col cols="12" sm="6" md="8" v-if="msgFactura != ''">
                                <v-alert  dense outlined :type="typeMsgFactura">
                                  {{msgFactura}}
                                </v-alert>
                              </v-col>
                            </v-row>
                          </v-card-subtitle> 
                        </div> 
                      </div>
                    </v-card> 
                  </v-col>

                  <v-col cols="12">
                    <v-card color="#1F7087"  dark v-if="validaCredito" >
                      <div class="d-flex flex-no-wrap justify-space-between">
                        <div>
                          <v-card-title class="text-h5">Crédito Refividrio</v-card-title> 
                          <v-card-subtitle >Crédito disponible {{formatMXN(creditoDisponible())}}</v-card-subtitle>
                          <v-card-actions>
                            <v-radio-group  v-model="methodPay"> 
                              <v-radio @click="changeMethodPay()" label="Crédito Refividrio" value="CRE" ></v-radio>
                            </v-radio-group>
                          </v-card-actions>
                        </div> 
                        <v-avatar class="ma-3" size="60" tile >
                          <v-img src="../../public/tdc.png" ></v-img>
                        </v-avatar>
                      </div>
                    </v-card> 
                  </v-col>

                  <v-col cols="12">
                    <v-card color="#305A81"  dark>
                      <div class="d-flex flex-no-wrap justify-space-between">
                        <div> 
                          <v-card-title class="text-h5">Transferencia electrónica</v-card-title> 
                          <v-card-subtitle > 
                            <v-btn rounded color="primary" @click="dialogTransferencia=true">
                                <v-icon left>mdi-format-list-bulleted-square</v-icon> 
                                Ver procedimiento 
                            </v-btn>
                          </v-card-subtitle> 
                          <v-card-actions>
                            <v-radio-group  v-model="methodPay"> 
                              <v-radio @click="changeMethodPay()" label="Transferencia electrónica" value="TRA" ></v-radio>
                            </v-radio-group>
                          </v-card-actions>
                        </div> 
                        <!-- <v-avatar class="ma-3" size="50" tile > -->
                          <!-- <v-img width="20%" src="../../public/transferencia-bancaria.svg" ></v-img> -->
                        <!-- </v-avatar> -->
                      </div>
                    </v-card> 
                  </v-col>

                </v-row>
              </v-container>  

              <v-container>
                <v-row dense> 
                  <v-col cols="12">
                    <v-card color="#C3DDF5">
                      <div class="d-flex flex-no-wrap justify-space-between">
                        <div>
                          <v-card-title class="text-h5">Pago en linea</v-card-title>
                          <v-card-subtitle >
                            Tu pago se acredita al momento.
                          </v-card-subtitle>    
                          <!-- env="sandbox"  
                              :items="payProducts"
                          -->
                            <PayPal 
                              :amount="calculaTotalCar_fn().toString()"
                              currency="MXN"
                              :client="credentials"  
                              v-on:payment-authorized="paymentAuthorized"
                              v-on:payment-completed="paymentCompleted"
                              v-on:payment-cancelled="paymentCancelled"
                              :button-style="paypalStyle"
                              ></PayPal>  
                        </div> 
                        <v-avatar class="ma-3" size="55" tile >
                          <v-img src="../../public/paypal.png" ></v-img>
                        </v-avatar>
                      </div>
                    </v-card> 
                  </v-col>
                  </v-row>
              </v-container>

              <v-container fluid v-if="methodPay=='TRA'"> 
                <v-alert type="warning" elevation="10"  colored-border border="bottom">
                  <div>Una vez que se valide tu pago te enviaremos un correo de confirmación de tu compra
                    en el cual se indicará a partir de que día estará disponible tu pedido para 
                    su entrega.</div>
                </v-alert>
              </v-container>
 
              <br><br><br> 
              <v-row>  
                <v-col class="mx-auto text-center">  
                  <v-btn 
                  @click="confirmarCompra()" class="mx-auto" style="color:#fff;" color="primary" width="250px" large>
                    CONFIRMAR COMPRA
                  </v-btn>            
                </v-col> 
              </v-row> 
          </v-card>    
        </v-container> 

        <v-container v-if="calculaTotalCar_fn() < montoMinimoCompra && creditoExedido == false"  style="min-height:656px;"  >
          <div>
            <v-btn text color="blue" @click="regresar()"><v-icon>mdi-arrow-left</v-icon>Regresar</v-btn>
            <v-card  style="min-height:286px;"> 
                <center>
                  <img class="my-5" src="../../public/carrito-de-compras.svg" width="100px" />
                </center>
                <p class="text-center" style="font-size: 1.5em;color :#909090">  
                La compra mínima es por el monto de<br> {{formatMXN(montoMinimoCompra)}}
                <br>El costo de tu carrito es de <br>{{formatMXN(calculaTotalCar_fn())}} 
                </p>
                <br> 
            </v-card> 
          </div>
        </v-container>
      </div> 
      <!-- <v-card>
        <v-btn @click="obtenerlocal()">
          prueba
        </v-btn>
        {{direccion}}
        Dir: {{direccionEnvio}}
      </v-card> -->
      <v-container v-if="creditoExedido==true && isLoad == false"  style="min-height:656px;"  >
        <div>
          <v-card  style="min-height:286px;"> 
              <center>
                <img class="my-5" src="../../public/credito.svg" width="100px" />
              </center>
              <p class="text-center" style="font-size: 1.5em;color :#909090">  
                Al parecer has excedido el tiempo límite para el pago de tu Crédito. 
              </p>
              <p class="text-center" style="font-size: 1.2em;color :#909090">  
                Debes saldar estas ordenes para seguir comprando en Refividrio.
              </p> 
              <v-card v-for="(item, i) in facturasPendientes" :key="i"  class="mx-auto" max-width="500" >
                <v-card-text> 
                  <center>
                    <p class="text-h5">No. Orden: {{item.orden}}</p>
                    <p>No. Factura: {{item.factura}}</p> 
                    <p class="text-h5" >{{formatMXN(item.pendientecobrar)}}</p>
                  </center>
                </v-card-text> 
              </v-card>
              
              <br> 
              <v-card class="mx-auto" max-width="90%" >
                <v-card-text> 
                  <center>
                    <p class="text-h5 text--primary">
                      Información que necesitas para realizar tu pago por Transferencia electrónica:
                    </p>
                    <p>Titular de la cuenta: RFV TRUCK PARTS AND ACCESSORIES SA DE CV</p> 
                    <p>Detalles de la cuenta: No. Cuenta: 01 17 36 56 59</p> 
                    <p>No. Cuenta CLABE: 0121 8000 1173 6565 99</p> 
                    <p>Dirección del banco: Bancomer</p> 
                    <p style="color:#13089B" >
                      <strong>
                        Por favor, incluye el número de orden en los detalles de la transferencia bancaria.
                      </strong>
                    </p>
                    <p class="text-h5"></p>
                  </center>
                </v-card-text> 
              </v-card>
              <br> 
          </v-card> 
        </div>
      </v-container>

    </template>

    <v-container v-if="shopingcarlength == 0 && isLoad == false && this.saleOrder.status_pay != 'pagado'"  style="min-height:656px;"  >
      <div>
        <v-card style="min-height:186px;"> 
            <p class="text-center" style="font-size: 2em;color :#909090">  
              Tu cesta está vacía
            </p> 
        </v-card> 
      </div>  
    </v-container>

  </v-container>  
  </div>  
</template>

<style scoped>
  .centered-input >>> input {
    text-align: center
  }
</style> 

<script> 
import config from '../json/config.json'
import axios from 'axios'; 
import PayPal from 'vue-paypal-checkout'
const validateRfc = require('validate-rfc');  
import AppMenu from '../components/Menu.vue';
import jsPDF from 'jspdf';
export default {
  name: "appBuy",
  data() {
    return { 
      ubicacionImagenProducto:'https://amoresens.com.mx/distribuidor/productos/'
      ,montoMinimoCompra : 1500
      ,montoParaRegalo : 100
      ,dialogRegalo : false
      ,dialogTransferencia: false
      ,creditoExedido : true
      ,msgFactura : ''
      ,typeMsgFactura : ''
      ,productos : []
      ,isLogged : false
      ,user : {}
      ,isLoad : false  
      ,isPay : false
      ,methodPay : 0
      // ,enaContinueMPay : true  
      ,credentials: {
        sandbox: 'AW7-JDeyq1fAlr6z7qSvDe8nQleAbYDLI90q4CKd4WwIXgE3EhedLlYY5uOG6iLkOGAEhev4Fo3eAD_I',
        production: 'AYf3eZYPLzr4W6cVSST2pXPWkdUMiFwf1_QHZeHRvIGPZSb_Kx-zBzt4WiUW-g9Oh6iT9iqIiOTuaDaO'
      }
      ,payProducts: []
      ,paypalStyle: {label:'checkout',size:'responsive',shape:'pill',color:'blue'}
      ,msgSucces:"",msgError:""
      ,saleOrder : {
        status_pay:"",paymentAuthorized:""
        ,paymentCompleted:"",paymentCancelled:""
        ,c_order_id:"-",documentno : "-"
        ,estado_pago:"-",_id : ""
        ,method_pay : ""
        ,productos:[]
        ,productoregalo : 0
      } 
      ,factura:false
      ,facturasPendientes : []
      ,productosRegalo : []

      ,pesoEnvioTotal : 0
      ,totalEnvio : 0
      ,totalMasEnvio: 0 // mandar el total del precio + envío
      ,direccionEnvio:[]
      ,userList:[] // Muestra las direciones para seleccionar el envío
      ,direccion:'' // Se guarda la dirreción seleccionada
      ,isPago: false // Muestra la ventana de pago
      ,isAddress: true // Muestra la ventana de selección de dirección
      
      ,paqueteria: true
      ,pagopaqueteria: 0

    }; 
  }, 
  async created() { 
    this.isLoad = true;  
    await this.validaLogin();
    await this.getproductsRegalo();
    if (this.isLogged) {
      await this.allProductinCar(); 
      await this.verifiedCreditExedido(); 
      await this.listadoDireccionesUsuario(this.user.c_bpartner_id);
      for (let index = 0; index < this.productos.length; index++) { 
          let Qtyordenada = 0;
          if ((this.productos[index].mex_quantytotal - this.productos[index].cantidad) < 0) {
            Qtyordenada =  (this.productos[index].mex_quantytotal - this.productos[index].cantidad) * -1;
          }
          this.saleOrder.productos.push(
          {
            "quantity": this.productos[index].cantidad
            ,"price": parseFloat(this.productos[index].l0)
            ,"total": (
                        parseFloat(this.productos[index].l0)
                          *
                        this.productos[index].cantidad
                      )
            ,"m_product_id":this.productos[index].m_product_id
            ,"cantidadExistente": this.productos[index].mex_quantytotal
            ,"cantidadOrdenada": Qtyordenada
            ,"value":this.productos[index].value  
            ,"name":this.productos[index].name  
          });
      }    
      this.saleOrder.c_bpartner_id = this.user.c_bpartner_id;
      this.saleOrder.ad_user_id = this.user.ad_user_id; 
      this.saleOrder.grandtotal = this.calculaTotalCar_fn(); 
      this.saleOrder.status_entrega = "pendiente";  
      this.saleOrder.m_pricelist_id = this.user.m_pricelist_id;
      
      this.calculaPesoTotalProductos();
      await this.obtenerCostoEnviov2();
      window.scrollTo(0,0);
    }else{
      this.menu('/shop/Login/');
    }
    this.isLoad = false;  
    //this.isSucursal = true; 
  }
  ,methods: {
    async confirmarCompra(){ 
      window.scrollTo(0,0);
      if (this.methodPay==0) {
        this.msgError = "Por favor selecciona una forma de pago.";
        return;
      }else{
        this.msgError = "";
      }  

      this.isLoad = true; 
      const resIns = await this.insertSaleOrder()
      if (resIns)this.menu("/shop/purchases");  
      this.isLoad = false;
    },
    async insertSaleOrder(){ 
      this.saleOrder.fechaprometida = this.recoleccion.fecha; 
      this.saleOrder.fechaprometidatexto = this.recoleccion.texto; 
      this.saleOrder.horaprometida = this.recoleccion.hora; 
      
      this.saleOrder.ad_org_recpt_id = 'Paquetería';
      this.saleOrder.isfactura = this.factura;  
      this.saleOrder.emailClient = this.user.email;  
      this.saleOrder.nombre_cliente = this.user.cpname;  
      this.saleOrder.grandtotal = this.totalMasEnvio; // Envía el total
      this.saleOrder.direccion = this.direccionEnvio; //Envía la direccion
      this.saleOrder.ordenSeg = null; //Envía la direccion

      this.saleOrder.paqueteria = true; //Se confirma si se env�a
      this.saleOrder.pagopaqueteria = this.pagopaqueteria; //Costo del Envio      
      
      const result = await axios.post(
        config.apiAmoresens + "/saleorder/add_auth_with_ad", 
        this.saleOrder
      ,{headers:{'token': this.$cookie.get('token')}})
      .then(res=>{  
        return res.data;
      }).catch(err=>{
        console.log(err);
        this.msgError = err;
        return false;
      });
      console.log(result);
      if(result.status == "success"){ 
        this.saleOrder = result.data;  
        this.emptyCar();
        return true;
      }else{ 
        window.scrollTo(0,0);
        if (result.data == "Error Interno") {
          this.msgError = "Existe un error, por favor Comuníquese con el soporte al número: 55-8103-5979";
        }else{
          this.msgError = result.data;
        } 
        return false; 
      }   
    },
    changeMethodPay(){ 
      this.recoleccion = {fecha:'',texto:'',horaprometida:''};
      if (this.methodPay  == "CRE" || this.methodPay  == "EFE" || this.methodPay  == "paypal") {
        // this.enaContinueMPay = false; 
        this.saleOrder.method_pay = this.methodPay;
        if (this.methodPay  == "CRE" || this.methodPay  == "EFE") { 
          this.saleOrder.status_pay = "pendiente";
          if (this.methodPay  == "CRE") {
            this.saleOrder.estado_pago = "procesado";
          }else{
            this.saleOrder.estado_pago = "pendiente";
          }
        } 
      }else if(this.methodPay  == "TRA"){
        this.dialogTransferencia = true;
        this.saleOrder.status_pay = "pendiente"; 
        this.saleOrder.estado_pago = "pendiente";
        this.saleOrder.method_pay = this.methodPay;
      }else{
        // this.enaContinueMPay = true;
        this.methodPay = 0;
        this.saleOrder.method_pay = "";
      }
    },

    paymentAuthorized: function (data) {
      this.saleOrder.paymentAuthorized = data;
      this.saleOrder.status_pay = "paymentAuthorized";
      this.saleOrder.method_pay = "paypal";
      this.methodPay = "paypal";   
    }, 

    paymentCompleted: async function (data) {
      if (data.state == "approved") {
        this.isLoad = true;
        this.saleOrder.paymentCompleted = data; 
        this.saleOrder.status_pay = "pagado"; 
        this.methodPay = "paypal";
        try {
          const OSInsert = await this.insertSaleOrder(); 
          if (OSInsert)this.menu("/shop/purchases");   
        } catch (error) {
          console.log(error);
        }
        this.acuse();
        this.isLoad = false;
      } else {
        this.msgError = "El pago no se pudo completar";
        console.log(data);
      } 
    },

    acuse(){
        let data =  this.saleOrder.paymentCompleted;   
        var doc = new jsPDF('p', 'pt',[500, 380]);
        var img = new Image()
        img.src = '/refivid.png'; 
        doc.addImage(img,'png', 150, 18, 0, 0);   

        var img2 = new Image()
        img2.src = '/paypal.png'; 
        img2.width = "10px";
        doc.addImage(img2,'png', 80, 18, 55, 55);    

        doc.setTextColor(0,106,164);
        doc.setFontSize(11);
        doc.text(64,120,`Estimado/a ${data.payer.payer_info.first_name} ${data.payer.payer_info.last_name}`);
        doc.text(64,140,`Ha enviado un pago por importe de $${data.transactions[0].amount.total} MXN`);
        doc.text(112,165,'¡Muchas gracias por tu compra!');

        doc.setTextColor(0,0,0);
        doc.setFontSize(10);
        doc.text(64,190,`Esta transacción puede tardar unos momentos en aparecer`);
        doc.text(64,200,`en su cuenta.`);

        doc.setFontSize(10);
        doc.setTextColor(129, 129, 129); 
        doc.text(64,240,`Orden:`);
        doc.text(160,240,`${this.saleOrder.documentno}`);
        doc.text(64,260,`Id. de transacción:`);
        doc.text(160,260,`${data.transactions[0].related_resources[0].sale.id}`);
        doc.text(64,280,`Estado:`);
        doc.text(160,280,`${data.transactions[0].related_resources[0].sale.state=='completed'?'COMPLETO':data.transactions[0].related_resources[0].sale.state}`);
       
        doc.text(64,300,`Modo transferencia:`);
        doc.text(160,300,`${data.transactions[0].related_resources[0].sale.payment_mode=='INSTANT_TRANSFER'?'TRANSFERENCIA INSTANTÁNEA':data.transactions[0].related_resources[0].sale.payment_mode}`);
      
        doc.text(64,320,`Id. de la compra:`);
        doc.text(160,320,`${this.saleOrder._id}`);

        doc.text(64,340,`Fecha transacción:`);
        doc.text(160,340,`${this.formatDate(data.transactions[0].related_resources[0].sale.create_time)} ${this.formatTime(data.transactions[0].related_resources[0].sale.create_time)}
        `); 
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            window.open(doc.output("bloburl"), "_blank");   
        } else {
            doc.save(`comprobante de pago${data.transactions[0].related_resources[0].sale.id}.pdf`);
        }
    },
    formatDate(dates) { 
        var month= ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"];  
        return `${(new Date(Date.parse(dates))).getDate()} de ${month[(new Date(Date.parse(dates))).getMonth()]} del ${(new Date(Date.parse(dates))).getFullYear()}`
    },
    formatTime(dates) { 
        const hours = ('0' + (new Date(Date.parse(dates))).getHours()).slice(-2)
        const minutes = ('0' + (new Date(Date.parse(dates))).getMinutes()).slice(-2)
        return `${hours}:${minutes}`
    },
    async emptyCar(){
      for (let index = 0; index < this.productos.length; index++) {
        const element = this.productos[index];
        await axios.delete(config.apiAmoresens + "/shopingcar/deletebyvalue_auth", 
        { headers:{token: this.$cookie.get('token')}, data:{value: element.value}})
        .then(res=>{ 
          if(res.data.status != "success"){ console.log(res.data);} 
        }).catch(err=>{console.log(err);});  
      } 
      let inputCar = document.getElementById("cantidadInCar");
      if (inputCar != null) {
        inputCar.value = "";
      }
    },
    paymentCancelled: function (data) {
      this.saleOrder.paymentCancelled = data;
      this.saleOrder.status_pay = "paymentCancelled";
      // this.enaContinueMPay = true; 
      this.methodPay = 0;
    },
    // :::::::::::::::::::::Module Regalo
    async getproductsRegalo(){
      this.productosRegalo = []; 
      let uri = config.apiAmoresens + "/productos/all";
      this.productosRegalo = await axios.get(uri
      ,{
        headers: { 'token': this.$cookie.get('token') },
        params: {
            filter: 'P15UN097'
            ,onliStock : 0
            ,range : [0,100]
            ,andalucia : true
            ,ld : true
            ,ordenMenorP : false
            ,ordenMayorP : false
            ,ordenMasVendido : false
        }
        
      }
      ).then(function (response) { 
        if (response.data.status == "success") {
          // console.log(response.data.data)
          return response.data.data;
        } else {
          console.log(response.data.data);
          return [];
        }
      }).catch(function (response){ 
        console.log(response);
        return [];
      });   
    }
    
    ,regaloSeleccionado(){
      if (this.saleOrder.productoregalo == 0) {
        this.msgError = "Por favor selecciona tu regalo.";
      }else{
          this.msgError = "";
          this.dialogRegalo = false;
      }
    }

    ,async modalRegalo(){
      window.scrollTo(0,0);
      if (this.calculaTotalCar_fn() >= this.montoParaRegalo) {
          this.msgError = "";
          this.dialogRegalo = true;
      }else{
          this.msgError = "";
          this.dialogRegalo = false;      } 
    },

    calculaTotalCar_fn(){
      let total = 0;
      for (let index = 0; index < this.productos.length; index++) {
        try {
          const element = this.productos[index];
          let subtotal =  (parseInt(element.cantidad) * parseFloat(element.siniva) * 1.16000000).toFixed(2);
          total += parseFloat(subtotal);
        } catch (error) { 
          return null;
        } 
      }
      this.totalMasEnvio = total+this.totalEnvio; // se envía el costo total con this.totalMasEnvio
      return parseFloat(this.totalMasEnvio).toFixed(2);
    },
    async mounted() {
      window.scrollTo(0,0); 
    },
    menu(path){
      if (this.$route.path !== path){
        this.$router.push(path);
      }  
    }
    ,async validaLogin(){
        this.user = await axios.get(config.apiAmoresens + "/user/userByToken", 
        {'headers': { 'token': this.$cookie.get('token') }})
        .then(res=>{return res.data;})
        .catch(err=>{return err;});    
        if (this.user.status == "success") {
          this.user = this.user.user; 
          const rfcRespuesta = validateRfc(this.user.taxid)
          if(rfcRespuesta.isValid && rfcRespuesta.type != "generic"){
            this.factura = true;
            this.msgFactura = `Esta compra generará una factura al RFC ${this.user.taxid}`;
            this.typeMsgFactura = 'info';
          }else{
            this.factura = false;
            this.msgFactura = `Esta compra NO generará factura, en caso 
            de requerirla ponte en contacto con tu agente de ventas o al número 55-8103-5979.`;
            this.typeMsgFactura = 'warning';
          } 
          this.isLogged = true;
        }else if(this.user.status == "unauthorized"){ 
          this.isLogged = false;
          location.href = "/";
        }   
    },
    async allProductinCar(){
        let productsincar = await axios.get(config.apiAmoresens + "/shopingcar/getcomplete_auth", 
        {
          'headers': { 'token': this.$cookie.get('token') }
        }).then(res=>{return res.data;})
        .catch(err=>{return err;});   
        if (productsincar.status == "success") {
          productsincar = productsincar.data;
        }else if(this.user.status == "unauthorized"){  
          productsincar = [];
        }  
        this.productos = productsincar;  
    }
    ,formatMXN(value) {
        var formatter = new Intl.NumberFormat('en-ES', {style: 'currency', currency: 'USD',});
        return `${formatter.format(value)} MXN`;
    }, 
    creditoDisponible(){
      return parseFloat(this.user.so_creditlimit) - parseFloat(this.user.so_creditused);
    }
    ,async verifiedCreditExedido(){
      let respuestaVC = await axios.get(config.apiAmoresens + "/partner/valida_credito"
        ,{headers:{'token': this.$cookie.get('token')}
      }).then(res=>{ 
        return res.data.data;
      }).catch(err=>{return err;});
      if (respuestaVC.status == 'success') {
        if (respuestaVC.data.length > 0) {
          this.facturasPendientes = respuestaVC.data;
          this.creditoExedido = true;
          window.scrollTo(0,0);
        }else{
          this.creditoExedido = false; 
        }
      }  
    },

    // :::::::::::::::::::::Modulo de Envio

    calculaPesoTotalProductos(){
      try {
        let totalPeso = 0;
        for (let index = 0; index < this.productos.length; index++) {
          try {
            const element = this.productos[index];
            let pesoProducto = (parseInt(element.cantidad) * element.producto_peso);
            totalPeso += parseFloat(pesoProducto);
          } catch (error) { 
            return null;
          } 
        }
        if (Number.isNaN(totalPeso)) {
          return 0;
        }else{

          this.pesoEnvioTotal = totalPeso;
          return parseFloat(totalPeso).toFixed(4);
        
        }
      } catch (error) {
        return 0;
      }
    },

    async obtenerCostoEnviov2(){

        let pesoFinal = this.pesoEnvioTotal;
        let parametros = {activo : true}
        let costoCalculado;

        this.coleccionCostoEnvio = await axios.get(config.apiAmoresens + "/paqueteriaguiaprepagada/obtener_costo",
        { headers:{token: this.$cookie.get('token')}, params : parametros})
        
        .then(res=>{return res.data;})
        .catch(err=>{return err;});
        if (this.coleccionCostoEnvio.status == "success") {
            this.coleccionCostoEnvio = this.coleccionCostoEnvio.data; 

            for (let index = 0; index < this.coleccionCostoEnvio.length; index++){
              const element = this.coleccionCostoEnvio[index];
              //console.log(element.costo);
              if ( pesoFinal>= element.intervalo1 && pesoFinal <= element.intervalo2) {
                  costoCalculado =  element.costo;
              }
            }

            if(costoCalculado >=0){
                this.totalEnvio = costoCalculado;
            }else{
              this.totalEnvio = 0;
            }

        }else{
              this.totalEnvio = 0;
        }

    },

    // :::::::::::::::::::::Cambios - Luis Aguilera
    async MandarDireccion(direccion){
      await axios.get(config.apiAmoresens + `/user/mDireccion/${direccion}`,
      {headers:{'token': this.$cookie.get('token')}
      }).then(res=>{
        this.direccionEnvio = res.data;
        // console.log(this.direccionEnvio);

      })
      .catch(err=>{
        console.log(err);
      })
    },
    
    //Muestra las direcciones al seleccionar por paqueteria
    async listadoDireccionesUsuario(c_bpartner_id){
      this.userList = await axios.get(config.apiAmoresens + `/user/addressList/${c_bpartner_id}`, 
      {headers:{'token': this.$cookie.get('token')}
      }).then(res =>{
        return res.data
      })
      .catch(err=>{
        return err;

      })
    },

    //Valida la selección de una dirección para enviar el pedido
    async sendAddress(){
      window.scrollTo(0,0);
      if(this.direccion == 0){
        this.msgError="Por favor selecciona una dirección";
        
      }else{
        this.msgError="";
        this.MandarDireccion(this.direccion);
        this.isAddress= false;
        this.isPago = true;

        // Se quita la opcion de regalo peticion de Lic. Jessica
        // await this.modalRegalo();

      }
    },

    async returnDireccion(){
      this.isPago=false;
      this.isAddress=true;
    },

    regresar(){
      this.menu('/shop/shopingcar/');
    },

  },
    
  components: {
    PayPal,AppMenu
  }

  ,computed: {
    validaCredito(){
      if (this.user.socreditstatus == "X") {
        try {
          if (this.creditoDisponible() > this.calculaTotalCar_fn()) {
            return true;
          }
        } catch (error) { 
          return false;
        } 
      } 
      return false;
    },
    shopingcarlength(){
      return this.productos.length;
    },  
  }
}
</script>
